import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Senate from "../components/ballot/senate.module.scss";
import AboveParty from "../components/ballot/partyAbove";
import BelowParty from "../components/ballot/partyBelow";
import Polly from "../components/ballot/pollyVote";
import Footer from "../components/footer";
import Metatags from "../components/metatags";

require('typeface-roboto-condensed');

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Metatags title="Australian Senate Ballot | May 18 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-18.png" url="https://cssgrid31.brett.cool" pathname="/may-18" mdxType="Metatags" />
    <div className={Senate.container}>
  <div className={Senate.header}>
    <h1>
      Senate Ballot Paper<br />
      <strong>State</strong> — Election of 6 Senators
    </h1>
  </div>
  <div className={`${Senate.instructions} ${Senate.above_line}`}>
    <h2>
      You may<br />
      vote in one of<br />
      two ways
    </h2>
    <div className={Senate.instructionArrow}>Either</div>
    <h3>Lazily</h3>
    <p>By numbering at least <strong>6</strong> of these boxes in the order of your choice (with number 1 as your first choice).</p>
  </div>
  <AboveParty letter="A" name="The slightly racist party" mdxType="AboveParty" />
  <AboveParty letter="B" name="The overtly racist party" mdxType="AboveParty" />
  <AboveParty letter="C" name="The only looks okay by comparison party" mdxType="AboveParty" />
  <AboveParty letter="D" name="The good option party" mdxType="AboveParty" />
  <AboveParty letter="E" name="The single policy party" mdxType="AboveParty" />
  <AboveParty letter="F" name="The Deceptively-named party" mdxType="AboveParty" />
  <AboveParty letter="G" name="The other good option party" mdxType="AboveParty" />
  <AboveParty letter="H" name="The People who prefer sliced bread for democracy sausage party" mdxType="AboveParty" />
  <div className={Senate.theLine} />
  <div className={`${Senate.instructions} ${Senate.below_line}`}>
    <div className={Senate.instructionArrow}>Or</div>
    <h3>Properly</h3>
    <p>By numbering at least <strong>12</strong> of these boxes in the order of your choice (with number 1 as your first choice), and struggling to decide who to preference last.</p>
  </div>
  <BelowParty name="The slightly racist party" mdxType="BelowParty">
    <Polly surname="Deemable" name="Irre" party="Slightly Racist Party" mdxType="Polly" />
    <Polly surname="Dingbat" name="Yuge" party="Slightly Racist Party" mdxType="Polly" />
  </BelowParty>
  <BelowParty name="The overtly racist party" mdxType="BelowParty">
    <Polly surname="Phobic" name="Xeno" party="Overtly racist party" mdxType="Polly" />
    <Polly surname="Supremacist" name="Wyatt" party="Overtly racist party" mdxType="Polly" />
    <Polly surname="Hasnone" name="Ideas" party="Overtly racist party" mdxType="Polly" />
  </BelowParty>
 
  <BelowParty name="The only looks okay by comparison party" mdxType="BelowParty">
    <Polly surname="Eater" name="Onion" party="O.L.O.B.C Party" mdxType="Polly" />
    <Polly surname="Visage" name="Potato" party="O.L.O.B.C Party" mdxType="Polly" />
  </BelowParty>
  <BelowParty name="The good option party" mdxType="BelowParty">
    <Polly surname="Green" name="Teresa" party="Good Option Party" mdxType="Polly" />
    <Polly surname="Yuss" name="Gene E." party="Good Option Party" mdxType="Polly" />
  </BelowParty>
  <BelowParty name="The single policy party" mdxType="BelowParty">
    <Polly surname="Vision" name="Tunnel" party="Single Policy Party" mdxType="Polly" />
  </BelowParty>
  <BelowParty name="The Deceptively-named party" mdxType="BelowParty">
    <Polly surname="Hass" name="Jack" party="Deceptively-named Party" mdxType="Polly" />
  </BelowParty>
 
  <BelowParty name="The other good option party" mdxType="BelowParty">
    <Polly surname="Wrights" name="Cybil" party="Other Good Option Party" mdxType="Polly" />
    <Polly surname="Eeya" name="Gladys" party="Other Good Option Party" mdxType="Polly" />
  </BelowParty>
  <BelowParty name="The People who prefer sliced bread for democracy sausage party" mdxType="BelowParty">
    <Polly surname="Wurst" name="Brät" party="Sausage Party" mdxType="Polly" />
  </BelowParty>
    </div>
    <Footer date={18} prev={true} next={true} mdxType="Footer">
      <p>{`Hey it’s `}<a parentName="p" {...{
          "href": "https://twitter.com/hashtag/democracysausage"
        }}>{`#democracysausage`}</a>{` day in Australia!`}</p>
      <p>{`In case you haven’t looked at a ballot sheet long enough today, here it is, recreated in CSS Grid and `}<em parentName="p">{`slightly less`}</em>{` racism.`}</p>
      <p>{`Also, not to drag anyone who actually voted above the line (I sure did).`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      